// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import LocationAnalysisType from '../../enums/LocationAnalysisType';
import { getTime } from '../../helpers/GeoSpatialHelper';

/**
 * @typedef Props
 * @property {import('mapbox-gl').LngLat | import('../../').Point} startPoint
 * @property {import('../../').Point} endPoint
 * @property {string} mapboxRoutingProfile
 * @property {boolean} [showIcon]
 *
 * @typedef State
 * @property {boolean} loading
 * @property {number} time time in min
 *
 * @extends {React.Component<Props, State>}
 */
class Time extends React.Component {
    constructor(props) {
        super(props);
        /** @type {State} */
        this.state = {
            loading: true,
            time: undefined,
        };
    }

    componentDidMount() {
        this.getTime();
    }

    static defaultProps = {
        showIcon: true,
    };

    async getTime() {
        const time = await getTime(
            this.props.startPoint,
            this.props.endPoint,
            this.props.mapboxRoutingProfile, // isochrone profile
        );
        this.setState({ loading: false, time });
    }

    render() {
        // Figure out the title
        let icon;
        const {
            /** @type {import('../../..').MapboxRoutingProfile} */
            mapboxRoutingProfile,
        } = this.props;
        switch (mapboxRoutingProfile) {
        case 'mapbox/driving':
            icon = LocationAnalysisType.DRIVING_TIME.ICON;
            break;
        case 'mapbox/walking':
            icon = LocationAnalysisType.WALKING_TIME.ICON;
            break;
        case 'mapbox/cycling':
            icon = LocationAnalysisType.CYCLING_TIME.ICON;
            break;
        }

        return (
            <div className="distance-time">
                {this.props.showIcon && (
                    <div className="flex-it center">
                        <i className="icon material-icons analysis-icon">
                            {icon}
                        </i>
                    </div>
                )}
                <div>
                    {this.state.loading
                        ? 'loading'
                        : `${this.state.time} min`}
                </div>
            </div>
        );
    }
}

Time.propTypes = {
    startPoint: PropTypes.object.isRequired,
    endPoint: PropTypes.object.isRequired,
    mapboxRoutingProfile: PropTypes.string.isRequired,
    showIcon: PropTypes.bool,
};

export default Time;
