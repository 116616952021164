import MetadataDataType from './MetadataDataType';

/**
 * @remark Special case for < and <=
 * For these two operators, we also check if the number is not null,
 * in order to not return facilities with a value of null in the results
 * (e.g. selecting 'less than' and writing in the minimum value, null will
 * always be considered as lesser than any other number, and will be returned
 * in the results array - this should not happen)
 */
const filterTypes = {
    GREATER_THAN: {
        text: 'dataBrowser.isGreaterThan',
        type: '>',
        evaluateFunction: (a, b) => a > b,
        labelFunction: value => `higher than ${value}`,
    },
    LESS_THAN: {
        text: 'dataBrowser.isLessThan',
        type: '<',
        evaluateFunction: (a, b) => a != null && a < b,
        labelFunction: value => `less than ${value}`,
    },
    EQUAL: {
        text: 'dataBrowser.isEqualTo',
        type: '==',
        evaluateFunction: (a, b) => a === b,
        labelFunction: value => `${value}`,
    },
    NOT_EQUAL: {
        text: 'dataBrowser.isNotEqualTo',
        type: '!=',
        evaluateFunction: (a, b) => a != null && a !== b,
        labelFunction: value => `not equal to ${value}`,
    },
    GREATER_THAN_OR_EQUAL: {
        text: 'dataBrowser.isGreaterThanOrEqualTo',
        type: '>=',
        evaluateFunction: (a, b) => a >= b,
        labelFunction: value => `${value} or higher`,
    },
    LESS_THAN_OR_EQUAL: {
        text: 'dataBrowser.isLessThanOrEqualTo',
        type: '<=',
        evaluateFunction: (a, b) => a != null && a <= b,
        labelFunction: value => `${value} or less`,
    },
    CONTAINS: {
        text: 'dataBrowser.contains',
        type: 'contains',
        evaluateFunction: (a, b) => a != null && a.includes(b),
        labelFunction: value => `contains ${value}`,
    },
};

/**
 * Returns the appropriate filters for the given type.
 *
 * @param {string} type - The data type (e.g., 'string', 'number').
 * @returns {Array} An array of filter types applicable to the specified type.
 */
export function getFiltersForType(type) {
    switch (type) {
        case 'string' || MetadataDataType.STRING:
            return [filterTypes.EQUAL, filterTypes.NOT_EQUAL, filterTypes.CONTAINS];
        default:
            return [
                filterTypes.GREATER_THAN,
                filterTypes.LESS_THAN,
                filterTypes.EQUAL,
                filterTypes.NOT_EQUAL,
                filterTypes.GREATER_THAN_OR_EQUAL,
                filterTypes.LESS_THAN_OR_EQUAL,
            ];
    }
}

export default filterTypes;
