// @ts-check
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import ClassesItemImage from '../ClassesItemImage';
import ClassesItemLabel from '../ClassesItemLabel';

/**
 * @typedef Props
 * @property  {import('../../../').LayerLibraryItemMetadata} metadata
 * @property {string} [className]
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {React.PureComponent<Props>}
 */
class LayerLibraryGroupInfo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    render() {
        const { className, metadata } = this.props;
        const {
            latestUpdate,
            source,
            visibility,
            classes,
            description,
        } = metadata;

        const groupInfoClasses = classNames(
            'layer-library-group__info',
            className,
        );

        const classItems = classes.map(item => (
            <div key={item.label}>
                <div className="flex-it center">
                    <ClassesItemImage
                        svg={item.svg}
                        type={item.type}
                        style={item.style}
                    />
                    <ClassesItemLabel label={item.label} />
                </div>
                {item.description && (
                    <span
                        className="layer-library-group__classes-item-description"
                        dangerouslySetInnerHTML={{ __html: item.description }} // eslint-disable-line react/no-danger
                    />
                )}
            </div>
        ));

        return (
            <div className={groupInfoClasses}>
                <div className="field field__description">
                    <div className="field-title">
                        {this.props.intl.formatMessage({ id: 'description' })}
                    </div>
                    <span
                        className="field-content"
                        dangerouslySetInnerHTML={{__html: description}} // eslint-disable-line react/no-danger
                    />
                </div>
                <div className="field">
                    <div className="field-title">{this.props.intl.formatMessage({ id: 'layerLibrary.latestUpdate' })}</div>
                    <div className="field-content">
                        <i className="material-icons font-15">date_range</i>
                        <span className="padded-left-10">
                            {latestUpdate}
                        </span>
                    </div>
                </div>
                <div className="field">
                    <div className="field-title">
                        {this.props.intl.formatMessage({ id: 'source' })}
                    </div>
                    <div className="field-content">
                        <div className="source-content">
                            <a
                                href={source.link}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {source.label}
                            </a>
                            <div className="source-link hidden">
                                <a
                                    href={source.link}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <FormattedMessage id="layerLibrary.downloadData" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <div className="field-title">{this.props.intl.formatMessage({ id: 'layerLibrary.visibility' })}</div>
                    <div className="field-content">{visibility}</div>
                </div>
                <div className="field">
                    <div className="field-title">{this.props.intl.formatMessage({ id: 'classes' })}</div>
                    <div className="field-content">
                        <div className="layer-library-group__class-items">
                            {classItems}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LayerLibraryGroupInfo.propTypes = {
    className: PropTypes.string,
    metadata: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

LayerLibraryGroupInfo.defaultProps = {
    className: '',
};

export default injectIntl(LayerLibraryGroupInfo);
