module.exports={
  "matchAll": "Match all",
  "matchAny": "Match any",
  "matchNone": "Match none",
  "isGreaterThan": "is greater than",
  "isLessThan": "is less than",
  "isEqualTo": "is equal to",
  "isNotEqualTo": "is not equal to",
  "isGreaterThanOrEqualTo": "is greater than or equal to",
  "isLessThanOrEqualTo": "is  less than or equal to",
  "contains": "contains",
  "percentage": "Percentage",
  "removeFilter": "Remove filter",
  "count": "Count",
  "percent": "Percent",
  "range": "Add values",
  "filterInfoBubbleTrue": "Area meets the set criteria",
  "filterInfoBubbleFalse": "Area doesn’t meet the selected criteria",
  "limitedData": {
    "infoText": "The data for filtering is limited to the currently selected survey. If you want to use data from a different survey, follow these steps:",
    "stepOne": "Go to {dataBrowserLink}",
    "stepTwo": "Select any variable from a survey by which you'd like to filter data.",
    "stepThree": "Return to the 'Filter Areas by Criteria' menu and create a filter.",
    "dataBrowserName": "Data Browser",
    "subtitle": "Limited to {surveyName} Data"
  }
}
